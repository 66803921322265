import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner, Modal } from "react-bootstrap";

const VideoGrid = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null); // To track the selected video

  useEffect(() => {
    const fetchVideos = () => {
      axios.get("https://api.ocupiiehospitalityacademy.com/api/videos/ftechall")
      // axios.get("https://04c4-2401-4900-1c3a-3e0c-3085-5039-cc7f-9a77.ngrok-free.app/api/videos/ftechall")
      
        .then((response) => {
          if (response.status === 200) {
            setVideos(response.data.videos);
          } else {
            throw new Error(`API Error: ${response.status}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching videos:", error);
          setError(
            "An error occurred: " +
              (error.response ? error.response.data : error.message)
          );
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchVideos();
  }, []);

  const handleThumbnailClick = (video) => {
    setSelectedVideo(video); // Set the selected video
  };

  const handleClose = () => {
    setSelectedVideo(null); // Clear the selected video
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading videos...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div style={{ textAlign: "center", color: "red" }}>{error}</div>;
  }

  return (
    <Container>
      <h2 className="text-center my-4">Videos</h2>
      <Row className="d-flex justify-content-center">
        {videos.map((video) => (
          <Col xs={12} sm={6} md={4} lg={3} className="mb-4" key={video.id}>
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "8px",
                padding: "10px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => handleThumbnailClick(video)}
            >
              <img
                src={`https://api.ocupiiehospitalityacademy.com/${video.thumbnail}`}
                alt={video.title}
                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              />
              <h5 className="mt-2">{video.title}</h5>
            </div>
          </Col>
        ))}
      </Row>

      {/* Modal for Playing the Selected Video */}
      {selectedVideo && (
        <Modal show={true} onHide={handleClose} size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedVideo.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video
              width="100%"
              controls
              style={{ borderRadius: "8px" }}
              autoPlay
            >
              <source
                src={`https://api.ocupiiehospitalityacademy.com/${selectedVideo.video_path}`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </Modal.Body>
        
        </Modal>
      )}
    </Container>
  );
};

export default VideoGrid;
