import React, { useState } from "react";
import axios from "axios";
import "./FeedbackForm.css";

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    Name: "",
    Email: "",
    Course: "",
    GeneralState: "",
    CourseContent: "",
    AudioVisual: "",
    LectureStructure: "",
    AvailabilityOfLearning: "",
    BatchTiming: "", // Correct field name
    Comments: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    // Validate required fields
    Object.keys(formData).forEach((key) => {
      if (!formData[key] && key !== "Comments") {
        newErrors[key] = `${key} is required`;
        valid = false;
      }
    });

    // Additional validation (e.g., email format)
    if (formData.Email && !/\S+@\S+\.\S+/.test(formData.Email)) {
      newErrors.Email = "Invalid email format";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Send data to backend
      axios.post("https://api.ocupiiehospitalityacademy.com/api/feedback/Submit", formData) // Replace with actual URL
        .then((response) => {
          // console.log("Feedback submitted:", response);
          alert("Feedback submitted successfully!");
          // Reset form
          setFormData({
            Name: "",
            Email: "",
            Course: "",
            GeneralState: "",
            CourseContent: "",
            AudioVisual: "",
            LectureStructure: "",
            AvailabilityOfLearning: "",
            BatchTiming: "",
            Comments: "",
          });
        })
        .catch((error) => {
          // console.error("Error submitting feedback:", error);
          alert("There was an error submitting your feedback. Please try again.");
        });
    }
  };

  return (
    <div className="feedback-form">
      <h3>Course Feedback Form</h3>
      <form onSubmit={handleSubmit}>
        {/* Name */}
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="Name"
            value={formData.Name}
            onChange={handleChange}
            required
          />
          {errors.Name && <p className="error">{errors.Name}</p>}
        </div>

        {/* Email */}
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="Email"
            value={formData.Email}
            onChange={handleChange}
            required
          />
          {errors.Email && <p className="error">{errors.Email}</p>}
        </div>

        {/* Course */}
        <div className="form-group">
          <label>Course</label>
          <select
            name="Course"
            value={formData.Course}
            onChange={handleChange}
            required
          >
            <option value="">Select Course</option>
            <option value="English">English</option>
            <option value="French">French</option>
            <option value="German">German</option>
            <option value="Spanish">Spanish</option>
            <option value="Arabic">Arabic</option>
          </select>
          {errors.Course && <p className="error">{errors.Course}</p>}
        </div>

        {/* Ratings */}
        {[
          { label: "General State of the Class", key: "GeneralState" },
          { label: "Course Content", key: "CourseContent" },
          { label: "Audio and Visual Connectivity", key: "AudioVisual" },
          { label: "Lecture Structure", key: "LectureStructure" },
          { label: "Availability of Learning Materials", key: "AvailabilityOfLearning" },
        ].map(({ label, key }) => (
          <div className="form-group" key={key}>
            <label>{label}</label>
            <div className="rating">
              {[1, 2, 3, 4, 5].map((num) => (
                <label key={num} className="rating-option">
                  <input
                    type="radio"
                    name={key}
                    value={num}
                    checked={formData[key] === `${num}`}
                    onChange={handleChange}
                    required
                  />
                  {num}
                </label>
              ))}
            </div>
            {errors[key] && <p className="error">{errors[key]}</p>}
          </div>
        ))}

        {/* Batch Timing */}
        <div className="form-group">
          <label>Batch Timing</label>
          <input
            type="time"
            name="BatchTiming"
            value={formData.BatchTiming}
            onChange={handleChange}
            required
          />
          {errors.BatchTiming && <p className="error">{errors.BatchTiming}</p>}
        </div>

        {/* Comments */}
        <div className="form-group">
          <label>Comments</label>
          <textarea
            name="Comments"
            value={formData.Comments}
            onChange={handleChange}
          ></textarea>
        </div>

        <button type="submit">Submit Feedback</button>
      </form>
    </div>
  );
};

export default FeedbackForm;
