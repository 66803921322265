import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Table, Button, Spinner } from "react-bootstrap";
import AddVideo from "./AddVideo";
import EditVideo from "./EditVideo";
import ViewVideo from "./ViewVideo"; // Import the ViewVideo component

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null); // For the selected video
  const [showAddModal, setShowAddModal] = useState(false); // For adding a new video
  const [showEditModal, setShowEditModal] = useState(false); // For editing a video
  const [showViewModal, setShowViewModal] = useState(false); // For viewing the video details
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10; // Number of records per page

  // Fetch videos when the component mounts
  useEffect(() => {
    const fetchVideos = () => {
       //axios.get("http://127.0.0.1:8000/api/videos/ftechall")
      axios.get("https://api.ocupiiehospitalityacademy.com/api/videos/ftechall")
        .then((response) => {
          if (response.status === 200) {
            setVideos(response.data.videos);
          } else {
            throw new Error(`API Error: ${response.status}`);
          }
        })
        .catch((error) => {
          console.error("Error fetching videos:", error);
          setError("An error occurred: " + (error.response ? error.response.data : error.message));
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchVideos();
  }, []);

  const token = localStorage.getItem("token");
  // Handle deleting a video
  const handleDelete = (id) => {
    axios.delete(`https://api.ocupiiehospitalityacademy.com/api/videos/delete/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          setVideos(videos.filter((video) => video.id !== id));
        }
      })
      .catch((error) => {
        console.error("Error deleting video:", error);
        setError("An error occurred: " + (error.response ? error.response.data : error.message));
      });
  };

  // Show Add Video Modal
  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  // Show Edit Video Modal
  const handleEdit = (video) => {
    setSelectedVideo(video);
    setShowEditModal(true);
  };

  // Show View Video Modal
  const handleView = (video) => {
    setSelectedVideo(video); // Set the selected video to view details
    setShowViewModal(true); // Show the view video modal
  };

  // Pagination logic
  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentVideos = videos.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(videos.length / recordPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPageRange = () => {
    const start = Math.max(1, currentPage - 2);
    const end = Math.min(totalPages, start + 4);
    const pageRange = [];
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }
    return pageRange;
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading videos...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return <div style={{ textAlign: "center", color: "red" }}>{error}</div>;
  }

  return (
    <Container>
      <Button variant="success" onClick={handleShowAddModal} className="mb-3">
        Add New Video
      </Button>
      <Container className="text-center">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Thumbnail</th>
            <th>Video</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentVideos.map((video) => (
            <tr key={video.id}>
              <td>{video.id}</td>
              <td>{video.title}</td>
              <td>
                <img
                  src={`https://api.ocupiiehospitalityacademy.com/${video.thumbnail}`}
                  alt={video.title}
                  style={{ width: "100px", height: "auto" }}
                />
              </td>
              <td>
                <video
                   src={`https://api.ocupiiehospitalityacademy.com/${video.video_path}`}
                  alt={video.title}
                  style={{ width: "100px", height: "auto" }}
                />
              </td>
              <td>
                <Button variant="primary" onClick={() => handleEdit(video)} className="mr-2">
                  Edit
                </Button>
                <Button variant="info" onClick={() => handleView(video)} className="ml-2">
                  View
                </Button>
                <Button variant="danger" onClick={() => handleDelete(video.id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination controls */}
      <div className="pagination-controls">
        <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </Button>
        {getPageRange().map((page) => (
          <Button
            key={page}
            onClick={() => paginate(page)}
            active={page === currentPage}
          >
            {page}
          </Button>
        ))}
        <Button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </Button>
      </div>

      </Container>


      {/* Add Video Modal */}
      {showAddModal && (
        <AddVideo
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          setVideos={setVideos}
        />
      )}

      {/* Edit Video Modal */}
      {showEditModal && (
        <EditVideo
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          selectedVideo={selectedVideo}
          setVideos={setVideos}
        />
      )}

      {/* View Video Modal */}
      {showViewModal && selectedVideo && (
        <ViewVideo
          showModal={showViewModal}
          setShowModal={setShowViewModal}
          video={selectedVideo}
        />
      )}
    </Container>
  );
};

export default VideoList;
