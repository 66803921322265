import React from "react";
import HospitalityIndustries from "./HospitalityIndustries";
import { Col, Container, Row } from "react-bootstrap";
import HospitalityGuidence from "./HospitalityGuidence";
import HospitalityCareer from './HospitalityCareer';

function Hospitality() {
  return (
    <Container className="mt-4">
      <Row>
        <Col xs={12} md={4} className="mb-4"> {/* Responsive column */}
          <HospitalityIndustries />
        </Col>
        <Col xs={12} md={4} className="mb-4"> {/* Responsive column */}
          <HospitalityGuidence />
        </Col>
        <Col xs={12} md={4} className="mb-4"> {/* Responsive column */}
          <HospitalityCareer />
        </Col>
      </Row>
    </Container>
  );
}

export default Hospitality;
