import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export
import { Row, Col, Container, Button, InputGroup, Form, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const OnlineInquiry = () => {
  // State for managing filters and pagination
  const [idFilter, setIdFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [monthFilter, setMonthFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [preferencesData, setPreferencesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10; // Records per page

  // Fetch preferences data from the backend
  useEffect(() => {
    const fetchPreferences = () => {
      const token = localStorage.getItem("token");

      axios
        .get("https://api.ocupiiehospitalityacademy.com/api/language-enquiry/fetchall?", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setPreferencesData(response.data);
          setFilteredData(response.data); // Initially display all 
          //  console.log(response.data)

        })
        .catch((error) => {
          console.error("Error fetching preferences data:", error);
        });
    };

    fetchPreferences();
  }, []);

  // Pagination logic
  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(filteredData.length / recordPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPageRange = () => {
    const start = Math.max(1, currentPage - 2);
    const end = Math.min(totalPages, start + 4);
    const pageRange = [];
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }
    return pageRange;
  };

  // Filter preferences data based on form fields
  useEffect(() => {
    const applyFilters = () => {
      const newFilteredData = preferencesData.filter((item) => {
        const matchesId = idFilter ? item.id.toString().includes(idFilter) : true;
        const matchesEmail = emailFilter
          ? item.Email.toLowerCase().includes(emailFilter.toLowerCase())
          : true;

        // Date filters
        const enquiryDate = new Date(item.enquiry_type.created_at);
        const matchesMonth = monthFilter ? enquiryDate.getMonth() + 1 === parseInt(monthFilter) : true;
        const matchesYear = yearFilter ? enquiryDate.getFullYear() === parseInt(yearFilter) : true;

        return matchesId && matchesEmail && matchesMonth && matchesYear;
      });

      setFilteredData(newFilteredData);
      setCurrentPage(1); // Reset pagination when filters change
    };

    applyFilters();
  }, [idFilter, emailFilter, monthFilter, yearFilter, preferencesData]);

  // Download Excel functionality
  const downloadExcel = () => {
    const dataToDownload = filteredData.length > 0 ? filteredData : preferencesData;

    const filteredDataForExcel = dataToDownload.map((item) => ({
      ID: item.id,
      Name: item.FullName,
      Mobile: item.Mobile,
      Email: item.Email,
      State: item.Select_state,
      Language: item.enquiry_type.Choose_Language,
      Preference: item.enquiry_type.Select_Prefrence,
      Date_of_Enquiry: item.enquiry_type.created_at,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Preferences");
    const filename = filteredData.length > 0 ? "Filtered_Preferences" : "All_Preferences";
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  return (
    <Container>
      

      {/* Filters in one row */}
      <Row className="mb-3">
        <Col sm={3}>
          <InputGroup>
            <InputGroup.Text>ID</InputGroup.Text>
            <Form.Control
              type="text"
              value={idFilter}
              onChange={(e) => setIdFilter(e.target.value)}
              placeholder="Filter by ID"
            />
          </InputGroup>
        </Col>

        <Col sm={3}>
          <InputGroup>
            <InputGroup.Text>Email</InputGroup.Text>
            <Form.Control
              type="text"
              value={emailFilter}
              onChange={(e) => setEmailFilter(e.target.value)}
              placeholder="Filter by Email"
            />
          </InputGroup>
        </Col>

        <Col sm={3}>
          <InputGroup>
            <InputGroup.Text>Month</InputGroup.Text>
            <Form.Control
              as="select"
              value={monthFilter}
              onChange={(e) => setMonthFilter(e.target.value)}
            >
              <option value="">Select Month</option>
              {[...Array(12)].map((_, index) => (
                <option key={index} value={index + 1}>
                  {new Date(0, index).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </Form.Control>
          </InputGroup>
        </Col>

        <Col sm={3}>
          <InputGroup>
            <InputGroup.Text>Year</InputGroup.Text>
            <Form.Control
              type="number"
              value={yearFilter}
              onChange={(e) => setYearFilter(e.target.value)}
              placeholder="YYYY"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
      <Col md={3}>
          <Button variant="success" className="mt-3" onClick={downloadExcel}>
            <i className="fa fa-download" aria-hidden="true"></i> Download Excel
          </Button>
        </Col>
      </Row>

      {/* Table to display preferences */}
      {filteredData.length > 0 ? (
        <Container className="text-center">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>ID</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>State</th>
                <th>Language</th>
                <th>Preference</th>
                <th>Date of Enquiry</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1 + (currentPage - 1) * recordPerPage}</td>
                  <td>{item.id}</td>
                  <td>{item.FullName}</td>
                  <td>{item.Mobile}</td>
                  <td>{item.Email}</td>
                  <td>{item.Select_state}</td>
                  <td>{item.enquiry_type.Choose_Language}</td>
                  <td>{item.enquiry_type.Select_Prefrence}</td>
                  <td>{item.enquiry_type.created_at}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>

            {getPageRange().map((page) => (
              <Button key={page} onClick={() => paginate(page)} active={currentPage === page}>
                {page}
              </Button>
            ))}

            <Button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </Button>
          </div>
        </Container>
      ) : (
        <p>No data found</p>
      )}
    </Container>
  );
};

export default OnlineInquiry;
