import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from 'xlsx';
import { Table, Form, InputGroup, Row, Col, Container, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const StudentInquiry = () => {
  const [profileData, setProfileData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const [emailFilter, setEmailFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [monthFilter, setMonthFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchProfileData = () => {
      const token = localStorage.getItem("token");

      axios
        .get("https://api.ocupiiehospitalityacademy.com/api/fetch_enquiries", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setProfileData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    const applyFilters = () => {
      const newFilteredData = profileData.filter((item) => {
        const matchesEmail = emailFilter
          ? item.Email.toLowerCase().includes(emailFilter.toLowerCase())
          : true;
        const matchesId = idFilter ? item.id.toString() === idFilter : true;

        const enquiryDate = new Date(item.date_of_enquiry);
        const matchesMonth = monthFilter ? enquiryDate.getMonth() + 1 === parseInt(monthFilter) : true;
        const matchesYear = yearFilter ? enquiryDate.getFullYear() === parseInt(yearFilter) : true;

        return matchesEmail && matchesId && matchesMonth && matchesYear;
      });

      setFilteredData(newFilteredData);
      setCurrentPage(1); // Reset to the first page when filters change
    };

    applyFilters();
  }, [emailFilter, idFilter, monthFilter, yearFilter, profileData]);

  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(filteredData.length / recordPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPageRange = () => {
    const start = Math.max(1, currentPage - 2);
    const end = Math.min(totalPages, start + 4);
    const pageRange = [];
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }
    return pageRange;
  };

  const downloadExcel = () => {
    const dataToDownload = filteredData.length > 0 ? filteredData : profileData;

    const filteredDataForExcel = dataToDownload.map(item => ({
      Id: item.id,
      Name: item.Name,
      Email: item.Email,
      Phone: item.Mobile_No,
      Select_City:item.Select_City,
      Select_State:item.Select_State,
      Date_of_Enquiry: item.date_of_enquiry,
      // Message: item.Message,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const filename = filteredData.length > 0 ? "Filtered_Records" : "All_Records";
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  return (
    <>

      <Row className="mb-3">
        <Col>
          <InputGroup>
            <InputGroup.Text>Email</InputGroup.Text>
            <Form.Control
              type="text"
              value={emailFilter}
              onChange={(e) => setEmailFilter(e.target.value)}
            />
          </InputGroup>
        </Col>

        <Col>
          <InputGroup>
            <InputGroup.Text>ID</InputGroup.Text>
            <Form.Control
              type="text"
              value={idFilter}
              onChange={(e) => setIdFilter(e.target.value)}
            />
          </InputGroup>
        </Col>

        <Col>
          <InputGroup>
            <InputGroup.Text>Month</InputGroup.Text>
            <Form.Control
              as="select"
              value={monthFilter}
              onChange={(e) => setMonthFilter(e.target.value)}
            >
              <option value="">Select Month</option>
              {[...Array(12)].map((_, index) => (
                <option key={index} value={index + 1}>
                  {new Date(0, index).toLocaleString('default', { month: 'long' })}
                </option>
              ))}
            </Form.Control>
          </InputGroup>
        </Col>

        <Col>
          <InputGroup>
            <InputGroup.Text>Year</InputGroup.Text>
            <Form.Control
              type="number"
              value={yearFilter}
              onChange={(e) => setYearFilter(e.target.value)}
              placeholder="YYYY"
            />
          </InputGroup>
        </Col>
      </Row>

      {/* <Button onClick={downloadExcel}>
        Download Records
      </Button> */}
     
      <Row>
      <Col md={3}>
          <Button variant="success" className="mt-3" onClick={downloadExcel}>
          <i className="fa fa-download" aria-hidden="true"></i> Download Excel
          </Button>
        </Col>
      </Row>

      {filteredData.length > 0 ? (
        <Container className="text-center">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Id</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Select City</th>
                <th>Select State</th>
                <th>Date of Enquiry</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((value, index) => (
                <tr key={value.id}>
                  <td>{index + 1 + (currentPage - 1) * recordPerPage}</td>
                  <td>{value.id}</td>
                  <td>{value.Name}</td>
                  <td>{value.Mobile_No}</td>
                  <td>{value.Email}</td>
                  <td>{value.Select_City}</td>
                  <td>{value.Select_State}</td>
                  <td>{value.date_of_enquiry}</td>
                  <td>{value.Message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="pagination-controls">
            <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>

            {getPageRange().map((page) => (
              <Button key={page} onClick={() => paginate(page)} active={currentPage === page}>
                {page}
              </Button>
            ))}

            <Button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </Button>
          </div>
        </Container>
      ) : (
        <Container className="text-center">
          <p>No records found matching your criteria. Please try different filters.</p>
        </Container>
      )}
    </>
  );
};

export default StudentInquiry;
