import React from "react";
import {Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Slide.css";

const Slide = () => {
  return (
      <>
      <Card className="slidercard">
        <Card.Img src="images/hospitalityhome.png" />
      </Card>
    </>
  );
};

export default Slide;
