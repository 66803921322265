import React from "react";
import { Link } from "react-router-dom";
import "./Help.css"

const Help = ({ onButtonClick }) => {
  return (
    <div className="navigation-links">
      <a href="/" className="list-item link-item">Home</a>
      <p className="list-item link-item" onClick={onButtonClick}>About Us</p>
      <Link to="/privacy-policy" className="list-item link-item">Privacy Policy</Link>
      <Link to="/terms-and-conditions" className="list-item link-item">Terms & Conditions</Link>
      <Link to="/refund-policy" className="list-item link-item">Refund Policy</Link>
    </div>
  );
};

export default Help;
