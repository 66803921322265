import React, { useState } from "react";
import {Button, Form, Modal } from 'react-bootstrap';

import "./Business.css";


const Student = () => {
    const [activeLogin, setActiveLogin] = useState(null);
    const [showModal, setShowModal] = useState(false);
    
    // Function to handle dropdown button click
    const showLoginForm = (type) => {
      setActiveLogin(type);
      setShowModal(true);
    };
    
    // Function to handle closing the modal
    const closeModal = () => {
      setActiveLogin(null);
      setShowModal(false);
    };

  return (
    <div className="corporate-links">
      {/* Center the content in the column */}
      <h5>For Students</h5> {/* Main label "For Students" */}
      <a
        href="https://drive.google.com/file/d/1I0sdM-DA18QUua0W_KfXf-VcXlO7wRTD/view?usp=sharing"
        className="corporate-link"
      >
        <strong>Download Your Prospectus</strong>
      </a>
      <button variant="primary" className="corporate-link" onClick={() => showLoginForm('enrollment')}>Check Your Enrollment</button>
      <button variant="primary" className="corporate-link" onClick={() => showLoginForm('certificate')}>Verify Your Certificate</button>
      
      {/* Modal for Login Form */}
     <Modal show={showModal} onHide={closeModal} keyboard={false} backdrop="static" >
        <Modal.Header closeButton>
          <Modal.Title>{activeLogin === 'enrollment' ? 'Check Your Enrollment' : 'Verify Your Certificate'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              width: "100%",
              backgroundColor: "#fffcc7",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            {/* Center Login Form */}
            {activeLogin === "enrollment" && (
              <Form>
               <Form.Group controlId="formName">
                  <Form.Label> Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Name"
                  />
                </Form.Group>
                <Form.Group controlId ="DateofBirth">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control type="text" placeholder="Enter your Date of Birth" />
                </Form.Group>
                <Form.Group controlId="formEnrollmentId">
                  <Form.Label>Enter Enrollment ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Enrollment"
                  />
                </Form.Group>

              <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            )}

            {/* Student Login Form */}
            {activeLogin === "certificate" && (
              <Form>
            
                <Form.Group controlId="formEnrollmentId">
                  <Form.Label>Enter Enrollment ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Enrollment"
                  />
                </Form.Group>
                <Form.Group controlId ="DateofBirth">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control type="text" placeholder="Enter your Date of Birth" />
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  style={{ marginTop: "5px" }}
                >
                  Submit
                </Button>
              </Form>
            )}
          </div>
        </Modal.Body>
      </Modal>
    
     
    </div>
  );
};

export default Student;
