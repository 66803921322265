import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import EnquiryForm from "../EnquiryForm/EnquiryForm";
import "bootstrap/dist/css/bootstrap.min.css";

function WhyChoose() {
  return (
    <Container fluid={true}>
      <Row>
        <Col md={7} style={{ backgroundColor: "transparent" }}>
          <h3 style={{ fontFamily: "math-italic", textAlign: "left" }}>
            Why Choose{" "}
            <strong style={{ color: "#ffad00" }}>
              Ocupiie Hospitality Academy{" "}
            </strong>
            ?
          </h3>
        </Col>
      </Row>
      <Row className="justify-content-between">
        <Col
          xm={12}
          md={5}
          lg={4}
          xl={4}
        

          style={{ backgroundColor: "transparent" }}
          className="mr-4 listcss"
        >
          <style>
            {`
            
              @media (max-width: 1030px) {
                .listcss {
                 width: fit-content;              

                }
              }

              @media (max-width: 768px) {
                .listss li {
                  font-size: 16px;
                }
              }
              @media (max-width: 576px) {
                .listss li {
                  font-size: 15px;               
                  
                }
              }
             
            `}
          </style>
          <ul
            className="listss"
            style={{
              fontSize: "25px",
              fontFamily: 'Merriweather',
              marginRight: "5px",
              color: "#080809",
              paddingTop: "10px",
              paddingLeft: "10px",
              // backgroundColor: "#9acd32",
              // backgroundImage: "url(/images/chooseus.jpeg)",
              backgroundSize: "fit-content", // Ensures the background fully covers the container
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
              listStylePosition: "inside", // Bullets inside the list item
              width: "100%", // Full width of the container
              // padding:"0"
            }}
          >
            <li>Industry-Ready Skills</li>
            <li>Globally Recognized Certifications</li>
            <li>Expert Faculty</li>
            <li>Job Placement Assistance</li>
            <li>Practical Learning Environment</li>
            <li>Personalized Career Support</li>
            <li>Global Internship Opportunities</li>
            <li>Flexible Learning Options</li>
            <li>Comprehensive Course Offerings</li>
            <li>A Pathway to Global Careers</li>
          </ul>
        </Col>
        <Col
          xm={12}
          md={7}
          lg={4}
          xl={4}

          style={{ alignItems: "right" }}
          className="enquerycsss"
        >
          <style>
            {`
            
              @media (max-width: 1024px) {
                .enquerycsss {
                 max-width: fit-content;
                }
              }
             
            `}
          </style>
          <EnquiryForm />
        </Col>
      </Row>
    </Container>
  );
}

export default WhyChoose;
