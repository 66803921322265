    import React from "react";
    import { Container, Row, Col, Card } from "react-bootstrap";
    import { FaArrowRightLong } from "react-icons/fa6";
    import "./WhyLearnWithOcupiie.css"; // Create and link your CSS file if needed
    
    const WhyLearnWithOcupiie = () => {
      const sections = [
        {
          title: "Commitment to Quality",
          content: [
            "Engage in cutting-edge learning techniques for better comprehension and retention.",
            "High-quality education at a price that doesn’t break the bank."
          ]
        },
        {
          title: "Dedicated Support System",
          content: [
            "Our team is here to support you at every step of your learning journey.",
            "Benefit from placement support and career counseling tailored to your aspirations."
          ]
        },
        {
          title: "Global Opportunities",
          content: [
            "Master global languages like English, French, Spanish, German, and Arabic to unlock international opportunities.",
            "Build a career in the dynamic and rewarding hospitality industry with our academy’s robust training programs."
          ]
        },
        {
          title: "Focus on Practical Skills",
          content: [
            "Gain practical knowledge and skills that are immediately applicable in real-world scenarios.",
            "Our programs are designed to meet current market demands, giving you a competitive edge."
          ]
        },
        {
          title: "Flexible and Accessible",
          content: [
            "Access interactive, engaging courses from the comfort of your home.",
            "Enjoy round-the-clock access to learning materials, enabling you to learn at your own pace."
          ]
        },
        {
          title: "Expertise and Excellence",
          content: [
            "Learn from certified professionals with industry expertise and a passion for teaching."
          ]
        },
        {
          title: "Diverse Learning Solutions",
          content: [
            "From hospitality training and language programs to visa and immigration services, we cater to diverse learning and career needs."
          ]
        }
      ];
    
      return (
        <Container className="mt-5 containerWhy">
        <Row>
          <Col className="whylernwith text-center" >
          <h2 className="text-center mb-4 ">Why learn with <strong style={{color:"#9ACD32"}}>OCUPIIE</strong>?</h2>
          </Col>
        </Row>      
          <Row xs={1} sm={2} md={3} lg={4} className="g-4">
            {sections.map((section, index) => (
              <Col key={index}>
                <Card className="shadow-sm cardmain">
                  <Card.Body className="shadowBody">
                    <Card.Title className="shadowBodyTitle"> <FaArrowRightLong/>
                    {section.title}</Card.Title>
                    <ul>
                      {section.content.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      );
    };
    
    export default WhyLearnWithOcupiie;