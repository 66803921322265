import React, { useState, useEffect, useMemo } from "react";
import { Card, Col, Row } from "react-bootstrap"; // Importing components from React-Bootstrap
import "./WhyForeignLanguage.css";

const WhyForeignLanguage = () => {
  // UseMemo to memoize the headings array
  const headings = useMemo(
    () => [
      {
        title: "Career Opportunities",
        points: [
          "Higher Earnings Potential",
          "Global Job Market",
          "Competitive Edge",
        ],
      },
      {
        title: "Adapt to the Global Economy",
        points: [
          "International Business",
          "Access to Information",
          "Study Abroad",
        ],
      },
      {
        title: "Improve Communication Skills",
        points: [
          "Bridge Cultural Gaps",
          "Effective Networking",
          "Adaptability",
        ],
      },
      {
        title: "Personal Growth and Fulfillment",
        points: [
          "Sense of Achievement",
          "Social Opportunities",
          "Life-long Learning",
        ],
      },
      {
        title: "Cultural Awareness",
        points: [
          "Understand New Perspectives",
          "Appreciation for Diversity",
          "Travel Benefits",
        ],
      },
    ],
    []
  );

  // State to track the current heading index and point index
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pointIndex, setPointIndex] = useState(0); // Track the point index for each heading

  // Slide every 5 seconds for heading change
  useEffect(() => {
    const headingIntervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % headings.length);
      setPointIndex(0); // Reset point index when title changes
    }, 5000); // Change content every 5 seconds

    return () => clearInterval(headingIntervalId); // Clean up the interval on unmount
  }, [headings.length]);

  // Slide each point every 1 second after the title changes
  useEffect(() => {
    const pointIntervalId = setInterval(() => {
      setPointIndex((prevIndex) => {
        if (prevIndex < headings[currentIndex].points.length - 1) {
          return prevIndex + 1;
        } else {
          clearInterval(pointIntervalId); // Stop the interval once all points have appeared
          return prevIndex;
        }
      });
    }, 1000); // Change points every 1 second

    return () => clearInterval(pointIntervalId); // Clean up the point interval on unmount
  }, [currentIndex, headings]);

  return (
    <div className="mt-3 m-0 p-0">
      <Row className="p-0 m-0 justify-content-center">
        <Col
          xs={12}
          sm={8}
          md={7}
          lg={8}
          xl={8}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <p className="badges m-0">
            Why is Learning a Foreign Language Essential?
          </p>
        </Col>
        <Col
          xs={11}
          sm={8}
          md={5}
          lg={4}
          xl={4}
          className="p-0 cardcolomncss"
        >
          <Card
            // border="danger"
            className="text-center m-0 p-0 cardcss"
            >
           <Card.Header className="cardcsstitle text-center">
                {headings[currentIndex].title}
              </Card.Header>
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">     
            
              <div
                className="points-list"
                style={{
                  height: "140px", // Fixed height for the points section
                  overflowY: "auto", // Enable scrolling if points overflow
                  padding: "0px", // Adjust padding if necessary
                  display: "block", // Ensure points are displayed in a block (vertically stacked)
                  justifyContent: "center", // Ensure content is centered
                  textAlign: "center", // Center align the text horizontally
                
                }}
              >
                {headings[currentIndex].points
                  .slice(0, pointIndex + 1)
                  .map((point, index) => (
                    <Card.Text
                      key={index}
                      className="text-center pt-1 points-list-card"
                    >
                      {point}
                    </Card.Text>
                  ))}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default WhyForeignLanguage;
