import React, { useState } from 'react';
import { Form, Button, FloatingLabel, Container } from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; // Axios for HTTP requests
import './EnquiryForm.css'; 

const BusinessEnquiryForm = () => {
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState(''); // Selected State field
  const [city, setCity] = useState(''); // Selected City field
  const [formData, setFormData] = useState({
    Name: '',          // Name field
    Mobile_No: '',     // Mobile_No field
    Email: '',         // Email field
    Investment: '',    // Investment field
    Message: ''        // Message field
  });

  // Object containing cities for each state
  const stateCities = {
"Andhra Pradesh": [
  "Amaravati", "Anantapur", "Chittoor", "Eluru", "Guntur", "Kadapa", "Kakinada", "Kurnool", "Machilipatnam", 
  "Nellore", "Ongole", "Rajahmundry", "Srikakulam", "Tirupati", "Visakhapatnam", "Vijayawada", "Vizianagaram",
  "Kothagudem", "Bapatla", "Narasaraopet", "Chilakaluripet", "Tadepalligudem", "Bobbili", "Nandyal", "Peddapalli",
  "Tanuku", "Rajampet", "Mandapeta", "Macherla", "Palnadu", "Chirala", "Jangareddygudem", "Malkangiri", "Kavali",
  "Proddatur", "Pithapuram", "Anakapalle", "Madanapalle", "Chittoor"
    ],
    "Arunachal Pradesh": [
  "Aalo", "Anini", "Basar", "Changlang", "Daporijo", "Itanagar", "Jairampur", "Jolmuk", "Khonsa", "Lohit", 
  "Namsai", "Pasighat", "Raga", "Roing", "Seppa", "Tawang", "Tezu", "Yazali", "Bomdila", "Miao",
  "Pangin", "Ziro", "Nirjuli", "Dirang", "Mahadevpur", "Siyum", "Ruksin", "Kaying", "Longding", "Tali",
  "Dibang Valley", "Lakhimpur", "Bordumsa", "Meka", "Namsai", "Rangfra", "Yingkiong", "Tawang", "Khellong",
  "Baliyang"
   ],
   "Assam": [
    "Baksa", "Barpeta", "Bongaigaon", "Dhubri", "Dibrugarh", "Guwahati", "Hojai/Lumding",
    "Lakhimpur", "Nalbari", "Patshala", "Silchar", "Sivsagar", "Tezpur", "Jorhat"
  ],
  "Bihar": [
  "Ara", "Aurangabad", "Bagaha", "Barh", "Begusarai", "Bettiah", "Bhagalpur", "Bihar Sharif", "Buxar", "Chandanpur", 
  "Chhapra", "Darbhanga", "Dalsinghsarai", "Dumraon", "Fatuha", "Gaya", "Hajipur", "Jamui", "Jhanjharpur", "Katihar", 
  "Khagaria", "Kishanganj", "Lakhisarai", "Madhobpur", "Madhubani", "Munger", "Motihari", "Nalanda", "Nawada", "Patna", 
  "Patna City", "Purnia", "Raxaul", "Rourkela", "Samastipur", "Sasaram", "Sheikhpura", "Siwan", "Vaishali"
  ],
   "Chhattisgarh": [
  "Bilaspur", "Durg", "Raipur", "Korba", "Bhilai", "Rajnandgaon", "Raigarh", "Jagdalpur", "Ambikapur", "Kanker", 
  "Kabirdham", "Rajnandgaon", "Jashpur", "Balod", "Dongargarh", "Pendra", "Dhamtari", "Bemetara", "Mungeli", "Mahasamund", 
  "Janjgir", "Champa", "Bhilai Nagar", "Chirmiri", "Pathalgaon", "Sakti", "Gariaband", "Gadchiroli", "Surguja", "Bilaspur City", 
  "Kawardha", "Pali", "Pandariya", "Lakhanpur", "Koriya", "Kusmunda", "Shivpur", "Tilda", "Dondilohara", "Bilhari", "Pendra Road"
   ],
   "Goa": [
  "Acora", "Agonda", "Alorna", "Anjuna", "Assagao", "Benaulim", "Betalbatim", "Betul", "Candolim", "Cortalim", 
  "Dona Paula", "Farmagudi", "Fatorda", "Galgibaga", "Grande Island", "Guirim", "Margaon", "Mapusa", "Miramar", 
  "Morjim", "Navelim", "Panjim", "Pilar", "Raia", "Ribandar", "Safa", "Sanguem", "Seraulim", "Shiroda", 
  "Sirvoi", "Soi", "Taleigao", "Tivim", "Verna", "Vasco da Gama", "Zambaulim", "Chandor", "Cortalim", "Ponda", 
  "Polem", "Quepem"
   ],
   "Gujarat": [
  "Ahmedabad", "Amreli", "Anand", "Bharuch", "Bhavnagar", "Bhuj", "Dahod", "Gandhinagar", "Gir Somnath", "Himatnagar",
  "Junagadh", "Kheda", "Mahesana", "Nadiad", "Navsari", "Narmada", "Patan", "Porbandar", "Rajkot", "Surat",
  "Sabar Kantha", "Valsad", "Vapi", "Vadodara", "Vallabh Vidyanagar", "Daman", "Veraval", "Bhadran", "Wadhwan",
  "Modasa", "Vijapur", "Unjha", "Surendranagar", "Ankleshwar", "Dholka", "Gandhidham", "Gandhinagar", "Palanpur",
  "Bardoli", "Ranavav", "Mahudha"
   ],
   "Haryana": [
  "Ambala", "Bhiwani", "Chandigarh", "Faridabad", "Fatehabad", "Gurugram", "Hisar", "Jhajjar", "Jind", "Kaithal", 
  "Karnal", "Kurukshetra", "Mahendragarh", "Mewat", "Palwal", "Panchkula", "Panipat", "Rewari", "Rohtak", "Sonepat", 
  "Sirsa", "Thanesar", "Bawal", "Hansi", "Narnaul", "Nuh", "Pehowa", "Pinjore", "Tosham", "Bapora", 
  "Charkhi Dadri", "Kalka", "Hodal", "Karnal City", "Mandi Dabwali", "Tigaon", "Barwala", "Gohana", "Dhandhuka", 
  "Farrukhnagar", "Bahadurgarh", "Pataudi"
  ],
  "Himachal Pradesh": [
  "Bilaspur", "Chamba", "Dharamshala", "Hamirpur", "Kullu", "Kangra", "Mandi", "Nahan", "Solan", "Una", 
  "Kasauli", "Palampur", "Renuka", "Nurpur", "Jwalamukhi", "Chintpurni", "Shimla", "Poanta Sahib", "Arki", 
  "Bharwain", "Jaswan", "Ghumarwin", "Nadaun", "Sundernagar", "Churah", "Banikhet", "Chail", "Jai Singh Puri", 
  "Chopal", "Sarkaghat", "Keylong", "Narkanda", "Kullu Valley", "Manali", "Chhatrari", "Baddi", "Nalagarh", 
  "Gaggal", "Baggi", "Kotla", "Padhar"
   ],
   "Jharkhand": [
  "Adityapur", "Bokaro", "Dhanbad", "Deoghar", "Hazaribagh", "Jamshedpur", "Ranchi", "Giridih", "Jharia", "Dumka", 
  "Chaibasa", "Lohardaga", "Ramgarh", "Pakur", "Saraikela", "Koderma", "Godda", "Khunti", "Simdega", "Latehar", 
  "Palamu", "Garhwa", "Bermo", "Chandil", "Hazaribagh", "Patamda", "Jamtara", "Raghunathganj", "Madhupur", 
  "Ghatshila", "Ranchi City", "Chandrapura", "Kokpar", "Mihijam", "Bundu", "Piska", "Gumla", "Tundi", 
  "Barkatha", "Jamtara Town", "Madhupur", "Tirla"
   ],
   "Karnataka": [
  "Bagalkot", "Bangalore", "Belgaum", "Bellary", "Bidar", "Bijapur", "Chikkamagaluru", "Chitradurga", "Davangere", 
  "Dharwad", "Gadag", "Hassan", "Hubli", "Hunsur", "Kalaburagi", "Karwar", "Kolar", "Koppal", "Mysore", "Mangalore", 
  "Raichur", "Ramanagaram", "Shimoga", "Tumkur", "Udupi", "Bagalkot", "Channarayapatna", "Hospet", "Karkala", "Lingsugur", 
  "Mandya", "Puttur", "Sira", "Sakleshpur", "Yadgir", "Channarayapatna", "Bantwal", "Gokak", "Athani", "Sirsi"
  ],
  "Kerala": [
  "Alappuzha", "Angamaly", "Kannur", "Kasaragod", "Kochi", "Kollam", "Kottayam", "Kozhikode", "Malappuram", 
  "Mavelikara", "Meenchanda", "Palakkad", "Payyannur", "Perumbavoor", "Punalur", "Sreekariyam", "Thrissur", 
  "Thiruvananthapuram", "Vadakara", "Varkala", "Wayanad", "Irinjalakuda", "Kochi", "Ponnani", "Kodungallur", 
  "Changanassery", "Chalakudy", "Ernakulam", "Thrippunithura", "Ottappalam", "Palai", "Manjeri", "Vypin", 
  "Edappally", "Kunnamkulam", "Kayani", "Cherthala", "Vatakara", "Karunagappally", "Aluva"
  ],
  "Madhya Pradesh": [
  "Bhopal", "Indore", "Gwalior", "Jabalpur", "Ujjain", "Sagar", "Rewa", "Dewas", "Shivpuri", "Chhindwara", 
  "Satna", "Hoshangabad", "Ratlam", "Mandsaur", "Sehore", "Vidisha", "Khargone", "Balaghat", "Khandwa", "Shahdol", 
  "Neemuch", "Guna", "Bhind", "Morena", "Tikamgarh", "Damoh", "Seoni", "Jhabua", "Panna", "Betul", 
  "Anuppur", "Chhatarpur", "Shivpuri", "Mandla", "Harda", "Shujalpur", "Rajgarh", "Barwani", "Ashok Nagar", 
  "Buxwaha", "Maihar"
  ],
  "Maharashtra": [
  "Ahmednagar", "Akola", "Alibag", "Amravati", "Aurangabad", "Bhandara", "Bhiwandi", "Buldhana", "Chandrapur", 
  "Dhule", "Gadchiroli", "Ichalkaranji", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Malegaon", "Malkapur", "Mumbai", 
  "Nagpur", "Nanded", "Nashik", "Navi Mumbai", "Parbhani", "Pimpri-Chinchwad", "Pune", "Raigad", "Ratnagiri", 
  "Sangli", "Satara", "Solapur", "Thane", "Ulhasnagar", "Vasai", "Vijayapura", "Wardha", "Yavatmal", 
  "Kalyan", "Karad", "Bharuch", "Shirdi"
  ],
  "Manipur": [
  "Bishnupur", "Chandel", "Churachandpur", "Imphal", "Jiribam", "Kakching", "Kangpokpi", "Kamjong", "Kangla", "Kumbi", 
  "Lamlai", "Lamshang", "Leimatak", "Longe", "Moirang", "Nambol", "Ningthoukhong", "Pallel", "Phungyar", "Senapati", 
  "Shirui", "Tengnoupal", "Thoubal", "Ukhrul", "Wangjing", "Wahengbam", "Koirengei", "Khonghampat", "Keirao", 
  "Lalambung", "Yairipok", "Tamelong", "Tengkong", "Thangjing", "Kakching Khunou", "Mongjam", "Lamlai Bazar", 
  "Wangbal", "Tera", "Singjamei", "Khangabok"
  ],
  "Meghalaya": [
  "Barapani", "Bharati", "Bismillah", "Cherrapunji", "Dalu", "Damra", "Gaulrith", "Garo Hills", "Jowai", "Khliehriat",
  "Kharang", "Laitlyngkot", "Laban", "Mawlai", "Mawphlang", "Mawkyrwat", "Mendipathar", "Mawkyrwat", "Mairang", "Nongstoin",
  "Nongpoh", "Nongkhrah", "Nongbah", "Pynursla", "Pahamsuk", "Resubelpara", "Shillong", "Sohra", "Sohphoh", "Tura",
  "Umling", "Umsning", "West Khasi Hills", "West Garo Hills", "Pynursla", "Tura", "Mairang", "Khliehriat", "Jowai",
  "Mendipathar"
  ],
  "Mizoram": [
  "Aizawl", "Bairabi", "Champhai", "Hnahthial", "Lunglei", "Kolasib", "Lawngtlai", "Mamit", "Serchhip", "Siaha",
  "Zokhawthar", "Saitual", "Kawnpui", "Tuipang", "Tlabung", "Kolasib", "Thenzawl", "Vairengte", "Hnahthial",
  "Zawlnuam", "Mualthuam", "N. Vanlaiphai", "Darlawn", "Bualpui", "Lungchhuan", "Champhai", "Rungpui", "Sailo",
  "Ngopa", "Sakahchhawng", "Bualpui", "Khawbung", "Kawnpui", "Zokhawthar", "Sairang", "Lunglei Town", "Mamit Town",
  "Vairengte", "Thenzawl", "Hnahthial"
   ],
    "Nagaland": [
  "Dimapur", "Kohima", "Mokokchung", "Tuensang", "Wokha", "Zunheboto", "Phek", "Mon", "Longleng", "Kiphire",
  "Bokajan", "Mangkolemba", "Chumoukedima", "Tseminyu", "Akuluto", "Medziphema", "Satoi", "Peren", "Phokhungri",
  "Changtongya", "Liphanyan", "Kohima Town", "Mokokchung Town", "Wokha Town", "Zunheboto Town", "Tizit", "Pughoboto",
  "Aghunato", "Chakabama", "Mokokchung Village", "Khriekema", "Longleng Town", "Seiyhama", "Chekiye", "Mokokchung",
  "Jalukie", "Kiphire Town", "Zunheboto Village", "Chukitong", "Chumukedima"
  ],
  "Odisha": [
    "Balangir", "Balasore", "Bargarh","Baripada", "Bhadrak", "Bhubaneswar","Brahmapur", "Cuttack", "Jeypore",
    "Jharsuguda", "Puri", "Rourkela", "Sambalpur","Talcher"
  ],
  "Punjab": [
  "Amritsar", "Bathinda", "Ferozepur", "Jalandhar", "Ludhiana", "Patiala", "Mohali", "Mansa", "Pathankot", "Hoshiarpur",
  "Rupnagar", "Kapurthala", "Sangrur", "Faridkot", "Moga", "Zira", "Barnala", "Fatehgarh Sahib", "Muktsar", "Tarn Taran",
  "Nawanshahr", "Shahid Bhagat Singh Nagar", "Malerkotla", "Sri Muktsar Sahib", "Samrala", "Khanna", "Doraha", "Khamano",
  "Budhlada", "Gurdaspur", "Abohar", "Raikot", "Batala", "Dera Bassi", "Bassi Pathana", "Mullanpur", "Patran", "Talwandi",
  "Zira", "Fattuwal", "Ghania Ke"
  ],
  "Rajasthan": [
  "Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bikaner", "Bundi", "Chittorgarh", "Dausa", "Dungarpur", 
  "Hanumangarh", "Jaipur", "Jaisalmer", "Jalore", "Jhalawar", "Jhunjhunu", "Karauli", "Kota", "Nagaur", "Pali", 
  "Pindwara", "Rajsamand", "Sawai Madhopur", "Sikar", "Sirohi", "Sri Ganganagar", "Tonk", "Udaipur", "Churu", 
  "Bhilwara", "Nathdwara", "Beawar", "Ranakpur", "Kishangarh", "Mandawa", "Kumbhalgarh", "Kota City", "Phalodi", 
  "Marwar", "Merta City", "Nimbahera", "Kota-Rajasthan"
   ],
   "Sikkim": [
  "Gangtok", "Aritar", "Jorethang", "Namchi", "Pakyong", "Rangpo", "Mangan", "Lachung", "Lingtam", "Singhik", 
  "Chungthang", "Yuksom", "Ravangla", "Singtam", "Tadong", "Sakya", "Tumin", "Kewzing", "Pelling", "Rinchenpong", 
  "Lachen", "Melli", "Khangchendzonga", "Bermiok", "Soreng", "Temi", "Mangan", "Khamdong", "Pochong", 
  "Batasia", "Sillery", "Lingzya", "Barfung", "Bokhiyong", "Bhaleybong", "Rangit", "Kedong", "Phodong", "Karthok", 
  "Khireshwar", "Sambuk"
  ],
  "Tamil Nadu": [
  "Chennai", "Coimbatore", "Madurai", "Trichy", "Salem", "Tirunelveli", "Erode", "Vellore", "Tiruppur", "Dindigul", 
  "Tanjore", "Kanchipuram", "Cuddalore", "Thoothukudi", "Pudukkottai", "Villupuram", "Kumbakonam", "Nagercoil", 
  "Sivakasi", "Rajapalayam", "Ramanathapuram", "Arakkonam", "Vikravandi", "Chidambaram", "Chengalpattu", "Pollachi", 
  "Sirkali", "Nagapattinam", "Karur", "Rishivandiyam", "Palladam", "Gingee", "Nellai", "Karaikal", "Sathyamangalam", 
  "Vedaranyam", "Udhagamandalam", "Kodaikanal", "Kallakurichi", "Tiruchengode"
   ],
   "Telangana": [
  "Adilabad", "Hyderabad", "Karimnagar", "Khammam", "Mahabubnagar", "Mancherial", "Nalgonda", "Nizamabad", 
  "Rangareddy", "Warangal", "Sircilla", "Peddapalli", "Medak", "Jagitial", "Jangaon", "Sangareddy", "Khagaznagar", 
  "Vemulawada", "Bhongir", "Miryalaguda", "Bhainsa", "Wanaparthy", "Mahabubabad", "Peddapalli", "Kothagudem", 
  "Nirmal", "Nagarkurnool", "Malkajgiri", "Gadwal", "Koratla", "Jubilee Hills", "Secunderabad", "Warangal City", 
  "Ramagundam", "Vikarabad", "Kishanbagh", "Medchal", "Shadnagar", "Yadagirigutta", "Pochampally", "Dornakal"
  ],
  "Tripura": [
  "Agartala", "Ambassa", "Belonia", "Dharmanagar", "Kailashahar", "Khowai", "Mohanpur", "Udaipur", "Bishalgarh", 
  "Sonamura", "Melaghar", "Sepahijala", "Ranir Bazar", "Bagma", "Maharani", "Kanchanpur", "Jirania", "Amarpur", 
  "Kailasahar", "Manu", "Sadar", "Barjala", "Srinagar", "Nalbari", "Shankarpur", "Madhupur", "Fatikroy", "Khowai Town", 
  "Kanchanpur", "Karbook", "Kalapania", "Rudrasagar", "Chandrapur", "West Tripura", "North Tripura", "South Tripura", 
  "West Agartala", "Tripura Sundari", "Takarjala", "Bishalgarh Town", "Bhubaneshwar"
  ],
  "Uttar Pradesh": [
  "Agra", "Aligarh", "Allahabad", "Ambedkar Nagar", "Azamgarh", "Badaun", "Baghpat", "Ballia", "Banda", "Barabanki", 
  "Bareilly", "Bijnor", "Chandauli", "Etah", "Etawah", "Faizabad", "Farrukhabad", "Fatehpur", "Gautam Buddh Nagar", 
  "Ghaziabad", "Gorakhpur", "Hamirpur", "Hapur", "Hardoi", "Jhansi", "Kanpur", "Kanshiram Nagar", "Kaushambi", 
  "Lakhimpur Kheri", "Lucknow", "Mathura", "Meerut", "Moradabad", "Muzaffarnagar", "Prayagraj", "Raebareli", 
  "Rampur", "Saharanpur", "Shahjahanpur", "Shamli", "Siddharthnagar", "Sitapur", "Varanasi", "Unnao", "Jaunpur", 
  "Mainpuri", "Sonbhadra", "Ballabhnagar", "Mau", "Budaun", "Chhapra", "Sultanpur", "Rae Bareli", "Kushi Nagar", 
  "Bijnor", "Ranchi", "Mathura-Vrindavan", "Firozabad", "Nagina", "Kanauj", "Lalitpur", "Jaunpur", "Rampur", 
  "Shahabad", "Balia"
  ],
  "Uttarakhand": [
  "Almora", "Bageshwar", "Baheri", "Bhimtal", "Chakrata", "Dehradun", "Haridwar", "Haldwani", "Kashipur", "Kedarnath", 
  "Kirtinagar", "Kotdwar", "Mussoorie", "Nainital", "Nanakmatta", "Pauri", "Pithoragarh", "Rishikesh", "Roorkee", 
  "Rudraprayag", "Srinagar", "Tanakpur", "Tehri", "Udham Singh Nagar", "Vikasnagar", "Ramnagar", "Sahaspur", "Jaspur", 
  "Haripur", "Paundi", "Muni ki Reti", "Gopeshwar", "Karanprayag", "Lansdowne", "Chamba", "Jwalapur", "Sultanpur", 
  "Pashulok", "Bhowali"
  ],
  "West Bengal": [
    "Alipurduar", "Asansol", "Bankura", "Barasat", "Basir hat", "Behrampur", "Burdawan",
    "Cooch Behar", "Dankuni", "Darjeeling", "Haldia", "Kaleempung", "Krishnanagar",
    "Malda", "Mecheda", "North 24 Pargana", "North Barrackpore", "Panchkura", "Purba Burdawan",
    "Purba Medinipur", "Raignaj", "Ranaghat", "Siliguri", "Sonapur", "Chandan Nagar", "Chinsurah",
    "Maynaguri", "Metli", "Sambalpur", "Durgapur", "Kharagpur", "Nadia"
  ],
  "Andaman and Nicobar Islands": [
  "Port Blair", "Diglipur", "Car Nicobar", "Mayabunder", "Rangat", "Kamorta", "Havelock", "Baratang", "Neil Island", 
  "Little Andaman", "Great Nicobar", "Campbell Bay", "Katchal", "Nancowry", "Chidiya Tapu", "Lohabarrack", "Chowra", 
  "Kalapathar", "Swaraj Dweep", "Long Island", "Tirap", "Kalapathar", "Wandoor", "Bambooflat", "Prothrapur"
  ],
  "Chandigarh": [
  "Chandigarh"
  ],
  "Dadra and Nagar Haveli and Daman and Diu": [
  "Daman", "Diu", "Silvassa", "Amli", "Khanvel", "Moti Daman", "Nani Daman", "Zampa"
  ],
  "Lakshadweep": ["Agatti", "Androth", "Amini", "Bangaram", "Bithra", "Chethlat", "Kadmat", "Kalapeni", "Kavaratti", 
    "Kiltan", "Minicoy", "Suheli Par", "Suheli", "Tinnakara"
  ],
  "Delhi": [
  "New Delhi", "Old Delhi", "Connaught Place", "Karol Bagh", "Saket", "Dwarka", "Vasant Vihar", "Rohini", "Mayur Vihar", 
  "Lajpat Nagar", "Pitampura", "Preet Vihar", "Rajouri Garden", "Hari Nagar", "Janakpuri", "Shahdara", "Hauz Khas", 
  "Green Park", "Kalkaji", "Jamia Nagar", "Okhla", "Kashmere Gate", "Seelampur", "Moti Bagh", "Naraina", "Nangloi", 
  "Paschim Vihar", "Dwarka", "Sarai Kale Khan", "Bhikaji Cama Place", "Kundli", "Shivaji Place", "Mundka", "Vikas Puri", 
  "Chandni Chowk", "Tughlakabad", "Subhash Nagar", "Kishanganj", "Vikramshila", "Baba Kharak Singh Marg"
  ],
  "Puducherry": [
  "Puducherry", "Auroville", "Karaikal", "Mahe", "Yanam"
  ],
  "Ladakh": [
  "Leh", "Kargil", "Nubra", "Zanskar", "Drass", "Padum", "Diskit", "Alchi", "Tso Moriri", "Tso Kar"
  ],









  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form default behavior (reloading the page)
    const form = event.currentTarget;

    // Validate form fields
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true); // Set form as validated

    // Prepare all the form data to send
    const dataToSend = {
      ...formData,      // Name, Mobile_No, Email, Investment, and Message
      Select_State: state,  // Select_State
      Select_City: city    // Select_City
    };
try{
    // Sending form data using axios
    const response= await axios.post("https://api.ocupiiehospitalityacademy.com/api/business_enquiries", dataToSend)
      // const response= await axios.post("http://127.0.0.1:8000/api/business_enquiries", dataToSend)
      
        if (response.status === 201) {
          // Handle success response
          alert('Form submitted successfully!');

          // Reset form fields
          setFormData({
            Name: '',
            Mobile_No: '',
            Email: '',
            Investment: '',
            Message: ''
          });
          setState(''); // Reset the state field
          setCity(''); // Reset the city field
        } else {
          // Handle non-200 responses
          alert('Something went wrong. Please try again.');
        }
    
    }
      catch(error) {
        // console.error('Error submitting the form:', error.response ? error.response.data : error.message);
        alert('Failed to submit the form. Please try again later.');
      };
  };

  // Update cities based on the selected state
  const handleStateChange = (e) => {
    setState(e.target.value);
    setCity(''); // Reset city when state changes
  };

  return (
    <Container>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <h4>Business Enquiry Form</h4>

        {/* Name Field */}
        <Form.Group controlId="name" className="mb-1">
          <FloatingLabel label="Full Name">
            <Form.Control
              type="text"
              name="Name" // Match backend field name
              value={formData.Name}
              onChange={handleInputChange}
              placeholder="Full Name"
              maxLength={255} // Corresponds to the backend rule
              required
            />
            <Form.Control.Feedback type="invalid">Please provide your full name.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Mobile Number Field */}
        <Form.Group controlId="mobile" className="mb-1">
          <FloatingLabel label="Phone Number">
            <Form.Control
              type="tel"
              name="Mobile_No" // Match backend field name
              value={formData.Mobile_No}
              onChange={handleInputChange}
              placeholder="Phone Number"
              pattern="[0-9]{10,12}" // Digits between 10-12
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid phone number (10-12 digits).</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Email Field */}
        <Form.Group controlId="email" className="mb-1">
          <FloatingLabel label="Email Address">
            <Form.Control
              type="email"
              name="Email" // Match backend field name
              value={formData.Email}
              onChange={handleInputChange}
              placeholder="Email Address"
               pattern="[\-a-zA-Z0-9~!$%^&amp;*_=+\}\{'?]+(\.[\-a-zA-Z0-9~!$%^&amp;*_=+\}\{'?]+)*@[a-zA-Z0-9_][\-a-zA-Z0-9_]*(\.[\-a-zA-Z0-9_]+)*\.[cC][oO][mM](:[0-9]{1,5})?"
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Investment Field */}
        <Form.Group controlId="investment" className="mb-1">
          <FloatingLabel label="Min. Investment 5 Lakh">
            <Form.Control
              type="number"
              name="Investment" // Match backend field name
              value={formData.Investment}
              onChange={handleInputChange}
              placeholder="Investment Amount"
              min="500000"
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a valid investment amount (min 5 lakh).</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* State Selection */}
        <Form.Group controlId="state" className="mb-1">
          <FloatingLabel label="Select State">
            <Form.Select value={state} onChange={handleStateChange} required>
              <option value="">Select State</option>
              {Object.keys(stateCities).map((stateName) => (
                <option key={stateName} value={stateName}>
                  {stateName}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select a state.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* City Selection */}
        <Form.Group controlId="city" className="mb-1">
          <FloatingLabel label="Select City">
            <Form.Select value={city} onChange={(e) => setCity(e.target.value)} required disabled={!state}>
              <option value="">Select City</option>
              {state && stateCities[state].map((cityName) => (
                <option key={cityName} value={cityName}>
                  {cityName}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">Please select a city.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Message Field */}
        <Form.Group controlId="message" className="mb-1">
          <FloatingLabel label="Your Message">
            <Form.Control
              as="textarea"
              name="Message" // Match backend field name
              value={formData.Message}
              onChange={handleInputChange}
              placeholder="Your Message"
              required
            />
            <Form.Control.Feedback type="invalid">Please provide a message.</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Submit Button */}
        <div className="d-flex justify-content-end align-items-end">
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </Container>
  );
};

export default BusinessEnquiryForm;
