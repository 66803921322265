import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form, Alert } from "react-bootstrap";

const EditVideo = ({ showModal, setShowModal, selectedVideo, setVideos }) => {
  const [editTitle, setEditTitle] = useState(selectedVideo?.title || "");
  const [editThumbnailFile, setEditThumbnailFile] = useState(null);
  const [editVideoFile, setEditVideoFile] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setEditTitle(selectedVideo?.title || "");
    setEditThumbnailFile(null); // Reset thumbnail file to ensure new file can be uploaded
    setEditVideoFile(null); // Reset video file to allow file update
  }, [selectedVideo]);

  const handleTitleChange = (e) => {
    setEditTitle(e.target.value);
  };

  // Handle video file change
  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditVideoFile(file);
    }
  };

  // Handle thumbnail file change
  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditThumbnailFile(file);
    }
  };

  // Validate input fields
  const validate = () => {
    if (!editTitle) {
      setError("Please enter a title.");
      return false;
    }
    if (!editThumbnailFile && !selectedVideo?.thumbnail) {
      setError("Please select a thumbnail image.");
      return false;
    }
    if (!editVideoFile && !selectedVideo?.video_path) {
      setError("Please select a video file.");
      return false;
    }
    setError("");
    return true;
  };

  const handleSaveEdit = () => {
    if (!validate()) return;

    // Prepare form data
    const updatedVideo = new FormData();
    updatedVideo.append("title", editTitle);
    if (editThumbnailFile) updatedVideo.append("thumbnail", editThumbnailFile);
    if (editVideoFile) updatedVideo.append("video", editVideoFile);

    const token = localStorage.getItem("token");

    // Make the PUT request to update the video
    // axios.put(`https://api.ocupiiehospitalityacademy.com/api/videos/update/${selectedVideo.id}`, updatedVideo)
    axios.post(
      `https://api.ocupiiehospitalityacademy.com/api/videos/update/${selectedVideo.id}?_method=PUT`,
      updatedVideo,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        console.log(response.data);
        if (response.status === 201) {
          console.log(response.data);
          setVideos((prevVideos) =>
            prevVideos.map((video) =>
              video.id === selectedVideo.id ? { ...video, ...response.data.video } : video
            )
          );
          setSuccess(true);
          setShowModal(false); // Close the modal
        }
      })
      .catch((error) => {
        console.error("Error editing video:", error);
        setError("An error occurred while editing the video.");
      });
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Video</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2 m-2">
        {/* Display success or error messages */}
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Video updated successfully!</Alert>}

        <Form>
          {/* Title Field */}
          <Form.Group controlId="editTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={editTitle}
              onChange={handleTitleChange}
              placeholder="Enter video title"
            />
          </Form.Group>

          {/* Thumbnail File Upload */}
          <Form.Group controlId="editThumbnail">
            <Form.Label>Thumbnail</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleThumbnailChange}
            />
            {selectedVideo?.thumbnail && !editThumbnailFile && (
              <Form.Text className="text-muted">Current Thumbnail: {selectedVideo.thumbnail}</Form.Text>
            )}
          </Form.Group>

          {/* Video File Upload */}
          <Form.Group controlId="editVideo">
            <Form.Label>Video</Form.Label>
            <Form.Control
              type="file"
              accept="video/*"
              onChange={handleVideoChange}
            />
            {selectedVideo?.video_path && !editVideoFile && (
              <Form.Text className="text-muted">Current Video: {selectedVideo.video_path}</Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveEdit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditVideo;
