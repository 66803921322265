import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa'; // Importing icons, including WhatsApp
import './SocialMedia.css'; // Keep the existing CSS
import { Container, Row, Col } from 'react-bootstrap';

const SocialMedia = () => {
    return (
        <Container className="social-media text-center">
            <h5>Social Media</h5>
            <Row className="justify-content-center">
                <Col xs="auto">
                    <a 
                        href="https://www.facebook.com/profile.php?id=61565898712218" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="social-link"
                    >
                        <FaFacebook size={30} />
                    </a>
                </Col>
                <Col xs="auto">
                    <a 
                        href="https://www.instagram.com/official_ocupiie/" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="social-link"
                    >
                        <FaInstagram size={30} />
                    </a>
                </Col>
                <Col xs="auto">
                    <a 
                        href="https://www.linkedin.com/company/103308395/admin/dashboard/" 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="social-link"
                    >
                        <FaLinkedin size={30} />
                    </a>
                </Col>
                <Col xs="auto">
                    <a 
                        href="https://wa.me/+916003344113"  // Replace with your WhatsApp number, including the country code but no "+" sign.
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="social-link"
                    >
                        <FaWhatsapp size={30} />
                    </a>
                </Col>
            </Row>
        </Container>
    );
}

export default SocialMedia;
