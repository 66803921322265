import React from 'react';
import { Dropdown} from 'react-bootstrap';
import './LoginButton.css';
import { Link } from 'react-router-dom';

function LoginButton() {
   return (
    <div className="Appss">
      <div className="login-dropdown">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic" className='loginDrop'>
            Login
          </Dropdown.Toggle>

          <Dropdown.Menu className='DropMenuCs'>
            <Dropdown.Item><Link to="/center-login" style={{ textDecoration: 'none'}}>Center Login</Link></Dropdown.Item>
            <Dropdown.Item><Link to="/student-login" style={{ textDecoration: 'none'}}>Student Login</Link></Dropdown.Item>         
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default LoginButton;
