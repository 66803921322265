import React from "react";
import { Modal} from "react-bootstrap";

const ViewVideo = ({ showModal, setShowModal, video }) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered className="m-2 p-4">
      <Modal.Header closeButton>
        <Modal.Title>{video.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-2 p-2">
        <h5>Created At</h5>
        <p>{new Date(video.created_at).toLocaleString()}</p>
        
        <h5>Thumbnail</h5>
        <img
          src={`https://api.ocupiiehospitalityacademy.com/${video.thumbnail}`}
          alt={video.title}
          style={{ width: "100%", height: "auto" }}
        />
        
        <h5>Video</h5>
        <video width="100%" controls>
          <source
            src={`https://api.ocupiiehospitalityacademy.com/${video.video_path}`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <h5>Last Updated</h5>
        <p>{new Date(video.updated_at).toLocaleString()}</p>
      </Modal.Body>
    </Modal>
  );
};

export default ViewVideo;
