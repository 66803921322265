import React, { useState } from 'react';
import { Form, Button, FloatingLabel, Container } from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios'; // Axios for HTTP requests
// import './EnquiryForm.css'; 

const EnquiryForm = () => {
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState(''); // Select_State field
  const [city, setCity] = useState(''); // Select_City field
  const [center, setCenter] = useState(''); // Choose_Center field
  const [formData, setFormData] = useState({
    Name: '',      // Name field
    Mobile_No: '', // Mobile_No field
    Email: '',     // Email field
    Message: ''    // Message field
  });

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form default behavior (reloading the page)
    const form = event.currentTarget;

    // Validate form fields
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true); // Set form as validated

    // Prepare all the form data to send
    const dataToSend = {
      ...formData, // Name, Mobile_No, Email, and Message
      Select_State: state,  // Select_State
      Select_City: city,    // Select_City
      Choose_Center: center // Choose_Center
    };
  

    try {
      // Sending form data using axios 
      const response = await axios.post("https://api.ocupiiehospitalityacademy.com/api/enquiries", dataToSend)
      // const response = await axios.post("http://127.0.0.1:8000/api/enquiries", dataToSend);
      // console.log(dataToSend)
      
      if (response.status === 201) {
        // Handle success response
        alert('Form submitted successfully!');
        
        // Reset form fields
        setFormData({
          Name: '',
          Mobile_No: '',
          Email: '',
          Message: ''
        });
        setState('');
        setCity('');
        setCenter(''); // Reset the center field
      } else {
        // Handle non-200 responses
        alert('Something went wrong. Please try again.');
      }
    } catch (error) {
      // console.error('Error submitting the form:', error.response ? error.response.data : error.message);
      alert('Failed to submit the form. Please try again later.');
    }
    
  };

  return (
    <Container className='p-0 m-0'>
       <Form noValidate validated={validated} onSubmit={handleSubmit} className="enquiry-formff">
      <h4> Student Enquiry Form</h4>

      {/* Name Field */}
      <Form.Group controlId="name" className="mb-1 m-0 p-0">
        <FloatingLabel label="Full Name">
          <Form.Control
            type="text"
            name="Name" // Match backend field name
            value={formData.Name}
            onChange={handleInputChange}
            placeholder="Full Name"
            maxLength={255} // Corresponds to the backend rule
            required
          />
          <Form.Control.Feedback type="invalid">Please provide your full name.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* Mobile Number Field */}
      <Form.Group controlId="mobile" className="mb-1">
        <FloatingLabel label="Phone Number">
          <Form.Control
            type="tel"
            name="Mobile_No" // Match backend field name
            value={formData.Mobile_No}
            onChange={handleInputChange}
            placeholder="Phone Number"
            pattern="[0-9]{10,12}" // Digits between 10-12
            maxLength={12}
            required
          />
          <Form.Control.Feedback type="invalid">Please provide a valid phone number (10-12 digits).</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* Email Field */}
      <Form.Group controlId="email" className="mb-1">
        <FloatingLabel label="Email Address">
          <Form.Control
            type="email"
            name="Email" // Match backend field name
            value={formData.Email}
            onChange={handleInputChange}
            placeholder="Email Address"
            pattern="[\-a-zA-Z0-9~!$%^&amp;*_=+\}\{'?]+(\.[\-a-zA-Z0-9~!$%^&amp;*_=+\}\{'?]+)*@[a-zA-Z0-9_][\-a-zA-Z0-9_]*(\.[\-a-zA-Z0-9_]+)*\.[cC][oO][mM](:[0-9]{1,5})?"
            required
          />
          <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* State Selection */}
      <Form.Group controlId="state" className="mb-1">
        <FloatingLabel label="Select State">
          <Form.Select value={state} onChange={(e) => setState(e.target.value)} required>
            <option value="">Select State</option>
            <option value="Assam">Assam</option>
            <option value="Odisha">Odisha</option>
            <option value="West Bengal">West Bengal</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">Please select a state.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* City Selection */}
      <Form.Group controlId="city" className="mb-1">
        <FloatingLabel label="Select City">
          <Form.Select value={city} onChange={(e) => setCity(e.target.value)} required>
            <option value="">Select City</option>
            <option value="Guwahati">Guwahati</option>
            <option value="Cuttack">Cuttack</option>
            <option value="Kolkata">Kolkata</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">Please select a city.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* Center Selection */}
      <Form.Group controlId="center" className="mb-1">
        <FloatingLabel label="Select Center">
          <Form.Select value={center} onChange={(e) => setCenter(e.target.value)} required>
            <option value="">Select Center</option>
            <option value="Center 1">Guwahati Center</option>
            <option value="Center 2">Kolkata Center</option>
            <option value="Center 3">Cuttack Center</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">Please select a center.</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* Message Field */}
      <Form.Group controlId="message" className="mb-1">
        <FloatingLabel label="Your Message (max 500 characters)">
          <Form.Control
            as="textarea"
            name="Message" // Match backend field name
            value={formData.Message}
            onChange={handleInputChange}
            placeholder="Your Message"
          
            required
          />
          <Form.Control.Feedback type="invalid">Please provide a message (max 500 characters).</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      {/* Submit Button */}
      <div className="d-flex justify-content-end align-items-end">
        <Button type="submit">Submit</Button>
      </div>
    </Form>
    </Container>
  );
};

export default EnquiryForm;