import React, { useState } from "react";
import { Container, Row, Col, Modal} from "react-bootstrap";
import "./FooterHome.css"; // Custom CSS file for styling
import SocialMedia from "./SocialMedia";
import Help from "./Help";
import Contact from "./Contact";
import Student from "./Student";
import Business from "./Business";


const FooterHome = ({ backgroundColor }) => {
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState("");


  const handleButtonClick = () => {
    setInfo(
      <>
        <p>
          we are dedicated to shaping the future of hospitality by providing
          world-class education and training. Our academy is committed to
          empowering individuals with the skills and knowledge they need to
          thrive in the ever-evolving hospitality industry. We specialize in
          offering a wide range of programs, including{" "}
          <b>
            Hospitality management, Food & Beverage operations, Language
            training, Recruitment services, and Security training
          </b>
          . With a global perspective and a local presence in
          <b> India</b>, we focus on creating professionals who are
          ready to meet the demands of the international hospitality landscape.{" "}
        </p>
        <p>
          Our programs are designed with a blend of
          <b> theoretical knowledge and practical experience</b>, ensuring that
          our graduates are job-ready and equipped to excel in their careers.
          Whether you are looking to enter the hospitality field or enhance your
          existing skills, Ocupiie Hospitality Academy provides the right
          platform for your professional growth.
        </p>
      </>
    );
    setShow(true);
  };


  const handleClose = () => setShow(false);


  return (
    <>
    <footer className="footer m-0 p-0" style={{ backgroundColor }}>
      <Container fluid={true} className="m-0 p-0">
        <Row className="footer-row p-0 m-0">
          <Col md={2} className="footer-section text-content-center ">
         
            <SocialMedia />
          </Col>
          <Col md={2} className="footer-section">
            <Help onButtonClick={handleButtonClick} />
          </Col>
          <Col md={2} className="footer-section text-content-center">
            <h5>Registered Address</h5>
            Maligaon,
            <br />
            Guwahati
            <br />
            Assam- 781011
          </Col>
          <Col md={2} className="footer-section text-content-center">
            <Business />
          </Col>
          <Col md={2} className="footer-section text-content-center">
            <Student />
          </Col>
          <Col md={2} className="footer-section text-content-center">
            <Contact />
          </Col>

        </Row>
      </Container>
     


      {/* Modal for About Us information */}
      <Modal show={show} onHide={handleClose}  className="modelbodyaboutusss">
        <Modal.Header closeButton style={{ backgroundColor: "#fbf5ed" }}>
          <Modal.Title>About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding:'5px'}}>{info}</Modal.Body>
      
      </Modal>
    </footer>
    <Container>
        <Row>
          <h6>Copyright © 2024 Ocupiie pvt. Ltd. All rights reserved.</h6>
        </Row>
      </Container>
      </>
  );
};


export default FooterHome;
