import axios from "axios";
import * as XLSX from "xlsx";
import React, { useEffect, useState } from "react";
import { Table, Button, Container, Form, InputGroup, Row, Col } from "react-bootstrap";

const BusinessInquiry = () => {
  const [businessData, setBusinessData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10;
  const [emailFilter, setEmailFilter] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const [monthFilter, setMonthFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  // Fetch data from backend
  useEffect(() => {
    const fetchData = () => {
      const token = localStorage.getItem("token");
      axios
        .get("https://api.ocupiiehospitalityacademy.com/api/fetchall", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setBusinessData(response.data);
          // console.log(response.data);
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    };
    fetchData();
  }, []);

  // Filter the data
  useEffect(() => {
    const applyFilters = () => {
      const newFilteredData = businessData.filter((item) => {
        const matchesEmail = emailFilter
          ? item.business_enquiry.Email.toLowerCase().includes(emailFilter.toLowerCase())
          : true;
        const matchesId = idFilter ? item.business_enquiry.id.toString() === idFilter : true;

        const enquiryDate = new Date(item.business_enquiry.date_of_enquiry);
        const matchesMonth = monthFilter ? enquiryDate.getMonth() + 1 === parseInt(monthFilter) : true;
        const matchesYear = yearFilter ? enquiryDate.getFullYear() === parseInt(yearFilter) : true;

        return matchesEmail && matchesId && matchesMonth && matchesYear;
      });

      setFilteredData(newFilteredData);
      setCurrentPage(1);
    };

    applyFilters();
  }, [emailFilter, idFilter, monthFilter, yearFilter, businessData]);

  // Pagination logic
  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(filteredData.length / recordPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPageRange = () => {
    const start = Math.max(1, currentPage - 2);
    const end = Math.min(totalPages, start + 4);
    const pageRange = [];
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }
    return pageRange;
  };

  // Download data as Excel
  const downloadExcel = () => {
    const dataToDownload = filteredData.length > 0 ? filteredData : businessData;

    const filteredDataForExcel = dataToDownload.map((item) => ({
      Id: item.business_enquiry.id,
      Name: item.business_enquiry.Name,
      Email: item.business_enquiry.Email,
      Phone: item.business_enquiry.Mobile_No,
      City: item.business_enquiry.Select_City,
      State: item.business_enquiry.Select_State,
      Investment: item.business_enquiry.Investment,
      Date_of_Enquiry: item.business_enquiry.date_of_enquiry,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const filename = filteredData.length > 0 ? "Filtered_Records" : "All_Records";
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  return (
    <Container>
      <Row className="mb-3">
        <Col>
          <InputGroup>
            <InputGroup.Text>Email</InputGroup.Text>
            <Form.Control
              type="text"
              value={emailFilter}
              onChange={(e) => setEmailFilter(e.target.value)}
            />
          </InputGroup>
        </Col>

        <Col>
          <InputGroup>
            <InputGroup.Text>ID</InputGroup.Text>
            <Form.Control
              type="text"
              value={idFilter}
              onChange={(e) => setIdFilter(e.target.value)}
            />
          </InputGroup>
        </Col>

        <Col>
          <InputGroup>
            <InputGroup.Text>Month</InputGroup.Text>
            <Form.Control
              as="select"
              value={monthFilter}
              onChange={(e) => setMonthFilter(e.target.value)}
            >
              <option value="">Select Month</option>
              {[...Array(12)].map((_, index) => (
                <option key={index} value={index + 1}>
                  {new Date(0, index).toLocaleString("default", { month: "long" })}
                </option>
              ))}
            </Form.Control>
          </InputGroup>
        </Col>

        <Col>
          <InputGroup>
            <InputGroup.Text>Year</InputGroup.Text>
            <Form.Control
              type="number"
              value={yearFilter}
              onChange={(e) => setYearFilter(e.target.value)}
              placeholder="YYYY"
            />
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <Button variant="success" className="mt-3" onClick={downloadExcel}>
            <i className="fa fa-download" aria-hidden="true"></i> Download Excel
          </Button>
        </Col>
      </Row>

      {businessData.length > 0 ? (
        <Container className="text-center">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Enquiry ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile No</th>
                <th>Investment</th>
                <th>City</th>
                <th>State</th>
                <th>Date of Enquiry</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((enquiry, index) => (
                <tr key={enquiry.id}>
                  <td>{index + 1 + (currentPage - 1) * recordPerPage}</td>
                  <td>{enquiry.business_enquiry.id}</td>
                  <td>{enquiry.business_enquiry.Name}</td>
                  <td>{enquiry.business_enquiry.Email}</td>
                  <td>{enquiry.business_enquiry.Mobile_No}</td>
                  <td>{enquiry.business_enquiry.Investment}</td>
                  <td>{enquiry.business_enquiry.Select_City}</td>
                  <td>{enquiry.business_enquiry.Select_State}</td>
                  <td>{enquiry.business_enquiry.date_of_enquiry}</td>
                  <td>{enquiry.business_enquiry.Message}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="pagination-controls">
            <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>

            {getPageRange().map((page) => (
              <Button
                key={page}
                onClick={() => paginate(page)}
                active={currentPage === page}
              >
                {page}
              </Button>
            ))}

            <Button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
        </Container>
      ) : (
        <div>
          <p>No records found matching your criteria. Please try different filters.</p>
        </div>
      )}
    </Container>
  );
};

export default BusinessInquiry;
