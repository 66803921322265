import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable"; // Import the jsPDF autotable plugin
import { Container, Form, Button, Row, Col } from "react-bootstrap";

function PaymentStatus() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(""); // Payment status will be passed via URL
  const [orderDetails, setOrderDetails] = useState({
    customer_name: "",
    father_name: "",
    customer_phone: "",
    customer_email: "",
    emergency_contact: "",
    address: "",
    state: "",
    city: "",
    preferred_class_timing: "",
    course_type: "",
    course_name: "",
    order_amount: "",
    duration: "",
    Date_Of_Enrollment: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Extract the customer and order details from the URL query parameters
    const customerDetails = {
      customer_name: searchParams.get("FullName"),
      father_name: searchParams.get("FatherName"),
      customer_phone: searchParams.get("Mobile"),
      customer_email: searchParams.get("Email"),
      emergency_contact: searchParams.get("EmergencyContact"),
      address: searchParams.get("Address"),
      state: searchParams.get("State"),
      city: searchParams.get("City"),
      preferred_class_timing: searchParams.get("PreferredClassTiming"),
      course_type: searchParams.get("Course_Type"),
      course_name: searchParams.get("Course_Name"),
      order_amount: searchParams.get("Amount"),
      duration: searchParams.get("Duration"),
      Date_Of_Enrollment:searchParams.get("Date_Of_Enrollment"),
    };

    setOrderDetails(customerDetails);

    // The payment status will also be passed in the URL
    const paymentStatus = searchParams.get("order_status");
    setStatus(paymentStatus);
  }, [searchParams]);

  // Function to handle downloading the order details as a PDF in table format
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const margin = 10;

    // Add company logo (if available) or address

    const logoUrl = "images/logos.png"; // URL or base64 for logo
    doc.text("INVOICE", 95, margin);
    doc.addImage(logoUrl, "PNG", margin, margin, 40, 30); // Logo
    doc.setFontSize(12);
    doc.text("PJB OCUPIIE PRIVATE LIMITED", 135, margin + 10); // Company name or address
    doc.text("Maligaon Chariali", 135, margin + 15); // Company address
    doc.text("Maligaon, Guwahati", 135, margin + 20);
    doc.text("Assam- 781011", 135, margin + 25);

    // Add a title


    // Prepare the data for the table
    const fields = [
    //   { label: "Order ID", value: searchParams.get("order_id") || "N/A" },

      { label: "Name", value: orderDetails.customer_name },
      { label: "Father's Name", value: orderDetails.father_name },
      { label: "Email", value: orderDetails.customer_email },
      { label: "Phone", value: orderDetails.customer_phone },
      { label: "Emergency Contact", value: orderDetails.emergency_contact },
      { label: "Address", value: orderDetails.address },
      { label: "State", value: orderDetails.state },
      { label: "City", value: orderDetails.city },
      { label: "Course Type", value: orderDetails.course_type },
      { label: "Course Name", value: orderDetails.course_name },
      {label: "Preferred Class Timing",value: orderDetails.preferred_class_timing},
      { label: "Duration", value: orderDetails.duration },
      { label: "Date_Of_Enrollment", value: orderDetails.Date_Of_Enrollment },
      { label: "Amount", value: `INR${orderDetails.order_amount}` },
      { label: "Payment Status", value: status || "N/A" },
    ];

    // Table columns and rows
    const tableRows = fields.map((field) => [
      field.label,
      field.value || "N/A",
    ]);

    // Create table in PDF
    doc.autoTable({
        head: [['Field', 'Details']],
        body: tableRows,
        startY: margin + 40,
        styles: {
          fontSize: 12,
        //   cellPadding: 5, // Adjust padding 
          lineWidth: 0.1,
          halign: 'left', // Horizontal alignment
        },
        headStyles: {
            fillColor: [255, 255, 255], // Background color of the header (blue)
            textColor: [0, 0, 0], // Text color for the header (white)
            fontStyle: 'bold', // Make header text bold            
          },
        columnStyles: {
          0: {
            fillColor: [255, 255, 255], // Background color for the first column (Field)
            textColor: [0, 0, 0], // Text color for the first column
          },
          1: {
            fillColor: [255, 255, 255], // Background color for the second column (Details)
            textColor: [0, 0, 0], // Text color for the second column
          }
        },
        rowHeight: 10, // Custom row height
      });

    // Download the PDF
    doc.save(`order_${searchParams.get("order_id") || "unknown"}.pdf`);
  };

  // Function to handle the navigation back to the home page
  const handleRedirect = () => {
    navigate("/"); // Redirect to home page
  };

  
  return (
    <Container className="mt-4">
      {/* <h1 className="mb-4">Order ID: {searchParams.get("order_id")}</h1>
      <h2 className="mb-4">Payment Status: {status}</h2> */}
      <h2>Enrollment Details</h2>


      {/* React-Bootstrap Form for displaying order details */}
      <Form>
      <Row>
        <Col>
        <Form.Group as={Col} controlId="oderId">
            <Form.Label>
              <strong>Order ID:</strong>
            </Form.Label>
            <Form.Control
              type="text"
              value={searchParams.get("order_id")}
              disabled
            />
          </Form.Group>
        </Col>
        <Col>
        <Form.Group as={Col} controlId="status">
            <Form.Label>
              <strong>Payment Status:</strong>
            </Form.Label>
            <Form.Control
              type="text"
              value={status}
              disabled
            />
          </Form.Group>
        </Col>
      </Row>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="formFullName">
            <Form.Label>
              <strong>Name:</strong>
            </Form.Label>
            <Form.Control
              type="text"
              value={orderDetails.customer_name}
              disabled
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formFatherName">
            <Form.Label>
              <strong>Father's Name:</strong>
            </Form.Label>
            <Form.Control
              type="text"
              value={orderDetails.father_name}
              disabled
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formEmail">
            <Form.Label>
              <strong>Email:</strong>
            </Form.Label>
            <Form.Control
              type="email"
              value={orderDetails.customer_email}
              disabled
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formPhone">
            <Form.Label>
              <strong>Phone:</strong>
            </Form.Label>
            <Form.Control
              type="text"
              value={orderDetails.customer_phone}
              disabled
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formEmergencyContact">
            <Form.Label>
              <strong>Emergency Contact:</strong>
            </Form.Label>
            <Form.Control
              type="text"
              value={orderDetails.emergency_contact}
              disabled
            />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formAddress">
            <Form.Label>
              <strong>Address:</strong>
            </Form.Label>
            <Form.Control type="text" value={orderDetails.address} disabled />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formState">
            <Form.Label>
              <strong>State:</strong>
            </Form.Label>
            <Form.Control type="text" value={orderDetails.state} disabled />
          </Form.Group>

          <Form.Group as={Col} controlId="formCity">
            <Form.Label>
              <strong>City:</strong>
            </Form.Label>
            <Form.Control type="text" value={orderDetails.city} disabled />
          </Form.Group>
        </Row>

        <Row className="mb-3">
        <Col>
        <Form.Group as={Col} controlId="formPreferredClassTiming">
            <Form.Label>
              <strong>Preferred Class Timing:</strong>
            </Form.Label>
            <Form.Control
              type="text"
              value={orderDetails.preferred_class_timing}
              disabled
            />
          </Form.Group>
        </Col>
        <Col>
        <Form.Group as={Col} controlId="formCourseType">
            <Form.Label>
              <strong>Course Type:</strong>
            </Form.Label>
            <Form.Control
              type="text"
              value={orderDetails.course_type}
              disabled
            />
          </Form.Group>
        </Col>
        <Col>
                 <Form.Group as={Col} controlId="formCourseName">
            <Form.Label>
              <strong>Course Name:</strong>
            </Form.Label>
            <Form.Control
              type="text"
              value={orderDetails.course_name}
              disabled
            />
          </Form.Group>
        </Col>        
        </Row>


        <Row className="mb-3">
          <Form.Group as={Col} controlId="formAmount">
            <Form.Label>
              <strong>Amount:</strong>
            </Form.Label>
            <Form.Control
              type="text"
              value={`₹${orderDetails.order_amount}`}
              disabled
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formDuration">
            <Form.Label>
              <strong>Duration:</strong>
            </Form.Label>
            <Form.Control type="text" value={orderDetails.duration} disabled />
          </Form.Group>

          <Form.Group as={Col} controlId="formDuration">
            <Form.Label>
              <strong>Date_Of_Enrollment:</strong>
            </Form.Label>
            <Form.Control type="text" value={orderDetails.Date_Of_Enrollment} disabled />
            
          </Form.Group>
        </Row>
      </Form>
      

      {/* Download PDF Button */}
      <Button variant="primary" onClick={handleDownloadPDF} className="mt-3">
        Download Order Details as PDF
      </Button>

      {/* Close and Redirect Button */}
      <Button
        variant="secondary"
        onClick={handleRedirect}
        className="mt-3 ms-3"
      >
        Close
      </Button>
    </Container>
  );
}

export default PaymentStatus;
