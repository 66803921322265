import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, Button, FloatingLabel } from "react-bootstrap"; // Import necessary components

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false); // To handle form validation
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation check
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);

    // Proceed with login if form is valid
    if (form.checkValidity() === true) {
      setError(""); // Reset error message

      // Send login request to the server
      axios
        .post("https://api.ocupiiehospitalityacademy.com/api/login", { email, password })
        .then((response) => {
          // On successful login, store token and navigate to the admin dashboard
          localStorage.setItem("token", response.data.token);
          navigate("/admin");
        })
        .catch((error) => {
          console.error("Error object:", error); // Log the whole error object for debugging

          if (error.response) {
            // If the response exists, check its structure
            const { data } = error.response;
            console.log("Error response data:", data); // Log the response data

            if (data?.error) {
              // If message exists, show it
              setError(data.error);
            } 
            else if (data?.errors) {
              // If validation errors exist, show them
              const fieldErrors = Object.values(data.errors).flat().join(" ");
              setError(fieldErrors);
            } 
            else {
              // If no specific error message or validation, show a generic error
              setError("Login failed: Unknown error");
            }
          } 
          else if (error.request) {
            // If no response was received
            setError("Login failed: No response from the server.");
          } else {
            // Errors in setting up the request
            setError("Login failed: " + error.message);
          }
        });
    }
  };

  return (
    <div className="login-container">
      <h2>Admin Login</h2>
      {/* Display error message if exists */}
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Form with validation */}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        {/* Email input with FloatingLabel inside Form.Group */}
        <Form.Group controlId="email" className="mb-3">
          <FloatingLabel label="Email address">
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Email address"
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email address.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Password input with FloatingLabel inside Form.Group */}
        <Form.Group controlId="password" className="mb-3">
          <FloatingLabel label="Password">
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid password.
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        {/* Submit button */}
        <Button type="submit" variant="primary">
          Login
        </Button>
      </Form>
    </div>
  );
};

export default Login;
