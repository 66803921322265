import React, { useState } from "react";
import axios from "axios";
import { Modal, Button, Form, Alert } from "react-bootstrap";

const AddVideo = ({ showModal, setShowModal, setVideos }) => {
  const [newTitle, setNewTitle] = useState("");
  const [newVideoFile, setNewVideoFile] = useState(null);
  const [newThumbnailFile, setNewThumbnailFile] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  // Handle video file change
  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewVideoFile(file);
    }
  };

  // Handle thumbnail file change
  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewThumbnailFile(file);
    }
  };

  // Handle title input change
  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  // Handle form submission
  const handleAddVideo = () => {
    // Validation
    if (!newTitle || !newVideoFile || !newThumbnailFile) {
      setError("Please fill all fields");
      return;
    }

    // Reset error and success messages
    setError("");
    setSuccess(false);

    // Create a FormData object
    const formData = new FormData();
    formData.append("title", newTitle);
    formData.append("video", newVideoFile);
    formData.append("thumbnail", newThumbnailFile);

    const token = localStorage.getItem("token");

    // Make the POST request using axios
    axios.post("https://api.ocupiiehospitalityacademy.com/api/videos/create", formData,
      
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      
    )
      // axios.post("https://api.ocupiiehospitalityacademy.com/api/videos/create",formData)
      .then((response) => {
        if (response.status === 201) {
          setVideos((prevVideos) => [...prevVideos, response.data.video]);
          setSuccess(true);
          setShowModal(false); // Close the modal
          console.log(response.data)
        }
      })
      .catch((error) => {
        console.error("Error adding video:", error);
        setError("An error occurred while uploading the video.");
      });
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add New Video</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2 m-2">
        {/* Display success or error messages */}
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Video added successfully!</Alert>}

        <Form>
          <Form.Group controlId="newTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={newTitle}
              onChange={handleTitleChange}
              placeholder="Enter video title"
            />
          </Form.Group>

          <Form.Group controlId="newThumbnail">
            <Form.Label>Thumbnail</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleThumbnailChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="newVideo">
            <Form.Label>Video</Form.Label>
            <Form.Control
              type="file"
              accept="video/*"
              onChange={handleVideoChange}
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleAddVideo}>
          Add Video
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddVideo;
