import React, { useEffect, useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx"; // Import XLSX library for Excel export
import { Row, Col, Container, Button, InputGroup, Form, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const FeedbackForm = () => {
  const [idFilter, setIdFilter] = useState(""); // Filter by ID
  const [emailFilter, setEmailFilter] = useState(""); // Filter by Email
  const [courseFilter, setCourseFilter] = useState(""); // Filter by Course
  const [feedbackData, setFeedbackData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 10; // Records per page

  // Fetch feedback data from the backend
  useEffect(() => {
    const fetchFeedback = () => {
      const token = localStorage.getItem("token");

      axios.get("https://api.ocupiiehospitalityacademy.com/api/FeedBack/Fetch", {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          setFeedbackData(response.data);
          setFilteredData(response.data); // Initially display all data
        })
        .catch((error) => {
          console.error("Error fetching feedback data:", error);
        });
    };

    fetchFeedback();
  }, []);

  // Pagination logic
  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(filteredData.length / recordPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getPageRange = () => {
    const start = Math.max(1, currentPage - 2);
    const end = Math.min(totalPages, start + 4);
    const pageRange = [];
    for (let i = start; i <= end; i++) {
      pageRange.push(i);
    }
    return pageRange;
  };

  // Filter feedback data based on form inputs
  useEffect(() => {
    const applyFilters = () => {
      const newFilteredData = feedbackData.filter((item) => {
        const matchesId = idFilter ? item.id.toString().includes(idFilter) : true;
        const matchesEmail = emailFilter ? item.Email.toLowerCase().includes(emailFilter.toLowerCase()) : true;
        const matchesCourse = courseFilter ? item.Course.toLowerCase().includes(courseFilter.toLowerCase()) : true;

        return matchesId && matchesEmail && matchesCourse;
      });

      setFilteredData(newFilteredData);
      setCurrentPage(1);
    };

    applyFilters();
  }, [idFilter, emailFilter, courseFilter, feedbackData]);

  // Download Excel functionality
  const downloadExcel = () => {
    const dataToDownload = filteredData.length > 0 ? filteredData : feedbackData;

    const filteredDataForExcel = dataToDownload.map((item) => ({
      ID: item.id,
      Name: item.Name,
      Email: item.Email,
      Course: item.Course,
      "General State": item.GeneralState,
      "Course Content": item.CourseContent,
      "Audio Visual": item.AudioVisual,
      "Lecture Structure": item.LectureStructure,
      "Availability of Learning": item.AvailabilityOfLearning,
      "Batch Timing": item.BatchTiming,
      Comments: item.Comments,
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Feedback");
    const filename = filteredData.length > 0 ? "Filtered_Feedback" : "All_Feedback";
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  return (
    <Container>
    
      <Row className="mb-3 justify-content-center">
        <Col md={3}>
          <InputGroup>
            <InputGroup.Text>ID</InputGroup.Text>
            <Form.Control
              type="text"
              value={idFilter}
              onChange={(e) => setIdFilter(e.target.value)}
              placeholder="Filter by ID"
            />
          </InputGroup>
        </Col>
        <Col md={3}>
          <InputGroup>
            <InputGroup.Text>Email</InputGroup.Text>
            <Form.Control
              type="text"
              value={emailFilter}
              onChange={(e) => setEmailFilter(e.target.value)}
              placeholder="Filter by Email"
            />
          </InputGroup>
        </Col>
        <Col md={3}>
          <InputGroup>
            <InputGroup.Text>Course</InputGroup.Text>
            <Form.Control
              type="text"
              value={courseFilter}
              onChange={(e) => setCourseFilter(e.target.value)}
              placeholder="Filter by Course"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Button variant="success" className="mt-3" onClick={downloadExcel}>
            <i className="fa fa-download" aria-hidden="true"></i> Download Excel
          </Button>
        </Col>
      </Row>

      {filteredData.length > 0 ? (
        <Container className="text-center">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Course</th>
                <th>General State</th>
                <th>Course Content</th>
                <th>Audio Visual</th>
                <th>Lecture Structure</th>
                <th>Availability of Learning</th>
                <th>Batch Timing</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1 + (currentPage - 1) * recordPerPage}</td>
                  <td>{item.id}</td>
                  <td>{item.Name}</td>
                  <td>{item.Email}</td>
                  <td>{item.Course}</td>
                  <td>{item.GeneralState}</td>
                  <td>{item.CourseContent}</td>
                  <td>{item.AudioVisual}</td>
                  <td>{item.LectureStructure}</td>
                  <td>{item.AvailabilityOfLearning}</td>
                  <td>{item.BatchTiming}</td>
                  <td>{item.Comments}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="pagination-controls">
            <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>
              Previous
            </Button>
            {getPageRange().map((page) => (
              <Button
                key={page}
                onClick={() => paginate(page)}
                active={page === currentPage}
              >
                {page}
              </Button>
            ))}
            <Button onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>
              Next
            </Button>
          </div>
        </Container>
      ) : (
        <div>No matching records found.</div>
      )}
    </Container>
  );
};

export default FeedbackForm;
