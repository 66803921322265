import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, FloatingLabel, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const EnquiryFormLanguage = () => {
  const [formData, setFormData] = useState({
    FullName: '',
    Mobile: '',
    Email: '',
    Select_state: '',
    Choose_Language: '',
    Select_Prefrence: '', // This will store the Learning Preference (Single/Group)
  });

  const [otpSent, setOtpSent] = useState(false);
  const [languageEnquiryId, setLanguageEnquiryId] = useState(null);
  const [otp, setOtp] = useState('');
  const [validated, setValidated] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false); // Track OTP verification success

  // List of Indian States
  const states = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", 
    "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", 
    "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", 
    "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", 
    "Uttarakhand", "Uttar Pradesh", "West Bengal", "Andaman and Nicobar Islands", 
    "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", 
    "Delhi", "Puducherry"
  ];

  const languages = [
    "Arabic", "English", "French", "German", "Spanish"
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    axios.post('https://api.ocupiiehospitalityacademy.com/api/language-enquiry/submit', formData)
      .then((response) => {
        // console.log('Form Submission Response:', response.data);  // Log response data for debugging
        alert(response.data.message);
        setOtpSent(true);
        setLanguageEnquiryId(response.data.language_enquiry_id); // Assuming backend returns this ID
      })
      .catch((error) => {
        // console.error('Form Submission Error:', error);
        alert(error.response?.data?.message || 'An error occurred');
      });
  };

  const handleVerifyOtp = (e) => {
    e.preventDefault();

    axios.post('https://api.ocupiiehospitalityacademy.com/api/verify-otp', {
      language_enquiry_id: languageEnquiryId,
      otp_code: otp,
    })
      .then((response) => {
        // console.log('OTP Verification Response:', response);  // Log the complete response
        if (response.data.success) {
          // OTP verification successful
          setVerificationSuccess(true);
          alert('OTP verified successfully!');
        } else {
          // OTP verification failed
          alert(response.data.message || 'OTP verification failed. Please try again.');
        }
      })
      .catch((error) => {
        // console.error('OTP Verification Error:', error);  // Log error details
        alert(error.response?.data?.message || 'An error occurred while verifying OTP.');
      });
  };

  return (
    <Container>
      <h4>{!otpSent ? 'Language Enquiry Form' : 'OTP Verification'}</h4>
      {!verificationSuccess ? (
        <Form noValidate validated={validated} onSubmit={!otpSent ? handleSubmit : handleVerifyOtp}>
          {!otpSent ? (
            <>
              {/* Full Name */}
              <Form.Group controlId="fullName" className="mb-3">
                <FloatingLabel label="Full Name">
                  <Form.Control
                    type="text"
                    name="FullName"
                    value={formData.FullName}
                    onChange={handleChange}
                    placeholder="Full Name"
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please provide your full name.</Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              {/* Mobile Number */}
              <Form.Group controlId="mobile" className="mb-3">
                <FloatingLabel label="Mobile Number">
                  <Form.Control
                    type="tel"
                    name="Mobile"
                    value={formData.Mobile}
                    onChange={handleChange}
                    placeholder="Mobile Number"
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please provide a valid mobile number.</Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              {/* Email Address */}
              <Form.Group controlId="email" className="mb-3">
                <FloatingLabel label="Email Address">
                  <Form.Control
                    type="email"
                    name="Email"
                    value={formData.Email}
                    onChange={handleChange}
                    placeholder="Email Address"
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please provide a valid email address.</Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              {/* State Selection */}
              <Form.Group controlId="state" className="mb-3">
                <FloatingLabel label="Select State">
                  <Form.Select
                    name="Select_state"
                    value={formData.Select_state}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select your state</option>
                    {states.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">Please select your state.</Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              {/* Language Preference */}
              <Form.Group controlId="language" className="mb-3">
                <FloatingLabel label="Preferred Language">
                  <Form.Select
                    name="Choose_Language"
                    value={formData.Choose_Language}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select your preferred language</option>
                    {languages.map((language, index) => (
                      <option key={index} value={language}>
                        {language}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">Please select a language.</Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              {/* Learning Preference - Dropdown */}
              <Form.Group controlId="preference" className="mb-3">
                <FloatingLabel label="Learning Preference">
                  <Form.Select
                    name="Select_Prefrence"
                    value={formData.Select_Prefrence}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select your learning preference</option>
                    <option value="Single">Single</option>
                    <option value="Group">Group</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">Please select your learning preference.</Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              {/* Submit Button */}
              <Button type="submit" className="btn btn-primary">
                Submit Enquiry
              </Button>
            </>
          ) : (
            <>
              {/* OTP Verification */}
              <Form.Group controlId="otp" className="mb-3">
                <FloatingLabel label="Enter OTP">
                  <Form.Control
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="OTP"
                    required
                  />
                  <Form.Control.Feedback type="invalid">Please enter the OTP sent to your email.</Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>

              {/* Verify OTP Button */}
              <Button type="submit" className="btn btn-primary">
                Verify OTP
              </Button>
            </>
          )}
        </Form>
      ) : (
        <div>
          <h5>Form Submitted Successfully!</h5>
          <p>Your enquiry has been successfully submitted and verified.</p>
        </div>
      )}
    </Container>
  );
};

export default EnquiryFormLanguage;
